


import { Component, Vue } from 'vue-property-decorator';
import { TTextChat } from '@/_types/text-chats/text-chat.type';
import simplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import cabinetChatsEditForm from '@/_modules/promo-cabinet/components/cabinet-chats-edit-form/cabinet-chats-edit-form.vue';
import cabinetChatsItem from '@/_modules/promo-cabinet/components/cabinet-chats-item/cabinet-chats-item.vue';
import textChatsApi, { TCreateEditTextChatQueryParams, TEditTextChatParams } from '@/_api/text-chats.api';
import draggable from 'vuedraggable';
import { TDragOptions } from '@/_types/drag-options.type';
import IconNoDiscussions from '@/_modules/icons/components/icon-no-discussions.vue';

const MAX_AMOUNT_OF_DISCUSSIONS = 40;

@Component({
  components: {
    simplePopup,
    cabinetChatsEditForm,
    cabinetChatsItem,
    draggable,
    IconNoDiscussions,
  }
})
export default class CabinetChats extends Vue {

  public chatList: TTextChat[] = [];
  public isListLoading: boolean = false;
  public isCreateEditPopupVisible: boolean = false;
  public isDeleteChatPopupVisible: boolean = false;
  public isDeletionInProgress: boolean = false;
  public textChatToDeleteId: number = null;
  public editingChatEntity: TTextChat = null;
  public isChatDragInProgress: boolean = false;
  public chatDragOptions: TDragOptions = {
    animation: 200,
    group: 'chatList',
    disabled: false,
  };

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get chatListPrepared(): TTextChat[] {
    return this.chatList;
  }

  public set chatListPrepared(value: TTextChat[]) {
    this.chatList = value;
  }

  public get isMaxAmountReached(): boolean {
    if (!this.chatListPrepared) {
      return false;
    }

    return this.chatListPrepared.length >= MAX_AMOUNT_OF_DISCUSSIONS;
  }

  public get isCreateNewDiscussionDisabled(): boolean {
    return this.isMaxAmountReached;
  }

  private sortChatList(chatList: TTextChat[]): TTextChat[] {
    const sortedChats: TTextChat[] = [];
    const unsortedChats: TTextChat[] = [];
    chatList.forEach((chat) => {
      if (chat.sorting > 0) {
        sortedChats.push(chat);
      } else {
        unsortedChats.push(chat);
      }
    });
    return sortedChats
      .sort((a, b) => {
        if (a.sorting < b.sorting) {
          return -1;
        } else if (a.sorting > b.sorting) {
          return 1;
        }
        return 0;
      })
      .concat(unsortedChats);
  }

  public mounted(): void {
    this.getTextChats();
  }

  private async getTextChats(): Promise<void> {
    this.isListLoading = true;
    const chats = await textChatsApi.getTextChats({ eventId: this.eventId });
    this.chatList = this.sortChatList(chats);
    this.isListLoading = false;
  }

  private openCreateEditPopup(): void {
    this.isCreateEditPopupVisible = true;
  }

  private closeCreateEditPopup(): void {
    this.isCreateEditPopupVisible = false;
    this.clearEditingChatEntity();
  }

  private openDeleteChatPopup(): void {
    this.isDeleteChatPopupVisible = true;
  }

  private closeDeleteChatPopup(): void {
    this.isDeleteChatPopupVisible = false;
  }

  private onEditChatClick(chat: TTextChat): void {
    this.editingChatEntity = chat;
    this.openCreateEditPopup();
  }

  private onDeleteChatClick(chat: TTextChat): void {
    this.textChatToDeleteId = chat.id;
    this.openDeleteChatPopup();
  }

  private onCancelDeleteChat(): void {
    this.textChatToDeleteId = null;
    this.closeDeleteChatPopup();
  }

  private async onConfirmDeleteChat(): Promise<void> {
    this.isDeletionInProgress = true;

    await textChatsApi.deleteTextChat({
      eventId: this.eventId,
      textChatId: this.textChatToDeleteId
    });

    this.textChatToDeleteId = null;
    this.closeDeleteChatPopup();
    this.isDeletionInProgress = false;
    this.getTextChats();
  }

  private onFormSuccess(): void {
    this.closeCreateEditPopup();
    this.clearEditingChatEntity();
    this.getTextChats();
  }

  private clearEditingChatEntity(): void {
    this.editingChatEntity = null;
  }

  private onChatDragStart(): void {
    this.isChatDragInProgress = true;
  }

  private onChatDragEnd(): void {
    this.isChatDragInProgress = false;
    this.saveChatListSorting();
  }

  private async saveChatListSorting(): Promise<void> {
    const resultingPromises: Promise<boolean>[] = [];
    this.chatListPrepared.forEach((chat: TTextChat, index: number) => {
      const chatClone: TTextChat = {...chat};
      chatClone.sorting = index + 1; // N.B. our logic is that zero sorting = no known sorting, so we have to make it non-zero
      delete chatClone.id;
      const chatData: TCreateEditTextChatQueryParams = {...chatClone};
      const payload: TEditTextChatParams = {
        eventId: this.eventId,
        chatId: chat.id,
        data: chatData,
      };
      resultingPromises.push(textChatsApi.editTextChat(payload));
    });
    await Promise.all(resultingPromises);
    this.getTextChats();
  }

  private async onVisibilityChanged(chat: TTextChat): Promise<void> {
    const chatClone: TTextChat = {...chat};
    chatClone.is_hidden = !chatClone.is_hidden;
    delete chatClone.id;
    const chatData: TCreateEditTextChatQueryParams = {...chatClone};
    const payload: TEditTextChatParams = {
      eventId: this.eventId,
      chatId: chat.id,
      data: chatData,
    };
    await textChatsApi.editTextChat(payload);
    this.getTextChats();
  }

}
