


import { Component, Prop, Vue } from 'vue-property-decorator';
import { TTextChat } from '@/_types/text-chats/text-chat.type';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';

const DEFAULT_AVATAR_EVENT = require('@/assets/images/no-avatar-event-60x60.svg');

@Component({
  components: {
    IconSquareEdit,
    IconSquareDelete,
  }
})
export default class CabinetChatsItem extends Vue {

  @Prop({type: Object})
  public readonly chat: TTextChat;

  @Prop({type: Number})
  public readonly positionNumber: number;

  public isChatHidden: boolean = false;

  public mounted(): void {
    this.isChatHidden = this.chat.is_hidden;
  }

  private onEditChatClick(): void {
    this.$emit('edit');
  }

  private onDeleteChatClick(): void {
    this.$emit('delete');
  }

  private getChatImageStyles(chat: TTextChat): { [key: string]: string } {
    let backgroundImageUrl = DEFAULT_AVATAR_EVENT;
    if (chat && chat.photo_url) {
      backgroundImageUrl = chat.photo_url;
    }
    return {
      'background-image': 'url(' + backgroundImageUrl + ')',
    };
  }

  private onVisibilityChange(): void {
    this.$emit('visibilityChanged');
  }

}
